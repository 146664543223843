// src/pages/callback.js
import React from "react"
import { handleAuthentication } from "../utils/auth"

const Callback = () => {
  handleAuthentication()

  return <p>En cours de chargement...</p>
}

export default Callback